import React, {useEffect, useLayoutEffect, useState} from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './App.css';

export const App = () => {
  const [baseHtml, setBaseHtml] = useState('<div/>')
  const [baseKeys, setBaseKeys] = useState<any>([])
  const [outfile, setOutputFile] = useState('translations')
  const [i18nOutput, seti18nOutput] = useState<any>({})
  const [langDefs, setLangDefs] = useState<any>({})
  const [warnings, setWarnings] = useState<any>([])
  const [showWarnings, setShowWarnings] = useState<any>(false)
  const [showLoadingKeys, setShowLoadingKeys] = useState<any>(true)
  const [showDownload, setShowDownload] = useState<any>(false)

  const findKeys = async (html: string) => {
    const searchPattern = /{{(.*?)}}/g
    const matches = await html.match(searchPattern);
    return matches || []
  }

  const replaceKeys = (html: string, keys: any) => {
    if (!keys) {
      return;
    }
    let _warnings: any[] = []
    let langResult: any = {}

    Object.keys(langDefs).map((locale: string) => {
      let newHtml = baseHtml
      keys.map((key: any) => {
        const targetKey = key.replace('{{', '').replace('}}','').trim()
        if (langDefs[locale][targetKey] === '') {
          const newWarnings = {locale, targetKey}
          _warnings.push(newWarnings)
          console.error(`Missing translation | locale: ${locale}, key: ${targetKey}`)
          return key;
        }
        // @ts-ignore
        newHtml = newHtml.replace(key, langDefs[locale][targetKey])
        // console.log(key);
        return key;
      })
      langResult[locale] = newHtml
    })
    setWarnings(_warnings)
    seti18nOutput(langResult);
  }

  const genTransPackage = () => {
    const zip = JSZip()
    Object.keys(langDefs).map((locale) => {
      zip.file(`${outfile}_${locale}.html`, i18nOutput[locale]);
    });
    zip.generateAsync({type: "blob"}).then(content => {
      saveAs(content, `${outfile}.zip`);
    });
  }

  useEffect(() => {
    if (!Object(langDefs)) {
      return;
    }
    replaceKeys(baseHtml, baseKeys)
  }, [baseKeys])

  useEffect(() => {
    if (i18nOutput['en']) {
      setShowDownload(true)
    }
  }, [i18nOutput])


  const fetchLangDefinitions = async () => {
    const resp = await fetch('https://hmw15x3zy9.execute-api.us-west-2.amazonaws.com/defs')
    if (resp.status !== 200) {
      console.log(`error: ${resp.status}`)
      return;
    }
    const data = await resp.json();
    setLangDefs(data);
  }

  useLayoutEffect(() => {
    fetchLangDefinitions()
  },[])

  useLayoutEffect(() => {
    setShowLoadingKeys(false)
  },[langDefs])

  useEffect(() => {
    findKeys(baseHtml).then((keys: string[]) => {
      setBaseKeys(keys);
    })
  }, [baseHtml])

  const onFilePathChange = async (event: any) => {
    const filepath = event.target.files[0];

    const fileReader = new FileReader();

    fileReader.onload = async res => {
      if (res && res.target && res.target.result) {
        const result = res.target.result.toString();
        setOutputFile(filepath.name.replace('.html',''))
        setBaseHtml(result);
      }
    };
    fileReader.onerror = err => console.log(err);
    fileReader.readAsText(filepath);
  }

  const loadingLokaliseStrings = (
    <h2>Please wait... loading keys from Lokalise.</h2>
  )

  const warningTable = (
    <table style={{textAlign: 'center', margin: "auto"}}>
      <thead>
      <tr>
        <th>Locale</th>
        <th>Key</th>
      </tr>
      </thead>
      <tbody>
      {warnings.map((warning: any) => (
        <tr key={`${warning.locale}-${warning.targetKey}`}>
          <td>{warning.locale}</td><td>{warning.targetKey}</td>
        </tr>
      ))}
      </tbody>
    </table>
  )

  const downloadSection = (
    <div>
      <h2>Step 2: Download Translations</h2>
      <hr/>
      <button onClick={() => genTransPackage()}>Download</button>
    </div>
  )

  return (
    <div className="App">
      <h1>Generate Translated HTML for all supported languages</h1>
      <hr/>
      { showLoadingKeys ? loadingLokaliseStrings : null}
      { !showLoadingKeys ? (
        <div>
          <h2>Step 1: Upload HTML template</h2>
          <hr/>
          <input type="file" accept={".html"} name="file_to_upload" id="file_to_upload" onChange={onFilePathChange} />
          <hr/>
        </div>
        ): null}
      { warnings.length > 0 ? (<div onClick={() => setShowWarnings(!showWarnings)}><b style={{color: 'red'}}>Warning! {warnings.length} empty translations!</b> <p>Click here for details!</p></div>) : null }
      { showWarnings && warnings.length > 0 ? warningTable : null}
      { showDownload ? downloadSection : null}
    </div>
  );
}

export default App;
